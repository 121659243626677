<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="600"
  >
    <v-card>
      <v-card-title>Paarden verplaatsen</v-card-title>
      <v-card-text>
        <v-form
          ref="form"
          v-model="valid"
        >
          <v-row>
            <v-col cols="6">
              <v-select
                v-model="stable"
                :items="stables"
                label="Stal"
                :menu-props="{ offsetY: true }"
                clearable
                outlined
                :rules="required"
                @input="selectStable"
              />
            </v-col>
            <v-col cols="6">
              <v-select
                v-model="location"
                :items="locations"
                item-value="_id"
                item-text="name"
                label="Locatie"
                :disabled="!stable"
                :menu-props="{ offsetY: true, closeOnContentClick: true }"
                :loading="locationsLoading"
                no-data-text="Geen locatie beschikbaar"
                return-object
                clearable
                outlined
                :rules="required"
              >
                <template #item="{ item }">
                  <v-list-item @click="location = item">
                    <v-list-item-content>
                      <v-list-item-title>{{ displayLocation(item) }}</v-list-item-title>
                      <v-list-item-subtitle>Aantal vrije plaatsen: {{ item.places - item.horses.length }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-select>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          text
          color="blue darken-1"
          @click="$emit('close', false)"
        >
          Annuleren
        </v-btn>
        <v-btn
          text
          color="success"
          :disabled="!valid"
          @click="relocate"
        >
          Verplaatsen
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { required } from '@/Helpers';
import { configAPI } from '@/services';
import locationAPI from '@/services/LocationAPI';
import { displayLocation } from '@/Helpers/location.helper.ts';

export default {
  name: 'HorseLocationPicker',
  props: {
    dialog: { type: Boolean },
    amount: { type: Number, required: true },
  },
  data: () => ({
    valid: false,
    stables: [],
    locations: [],
    location: {},
    stable: '',
    locationsLoading: false,
  }),
  computed: { required },
  mounted() {
    this.getStables();
  },
  methods: {
    displayLocation,
    selectStable(stable) {
      if (stable) {
        this.getLocations(stable);
      }
    },
    relocate() {
      this.$emit('relocate', this.location);
      this.$refs.form.reset();
    },
    async getLocations(stable) {
      this.locationsLoading = true;
      try {
        const {
          data: { availableLocations },
        } = await locationAPI.getLocations({ stable });
        this.locations = availableLocations;
      } catch (err) {
        await this.$store.dispatch('errorMessage/setErrorMessage', err.message);
      } finally {
        this.locationsLoading = false;
      }
    },
    async getStables() {
      try {
        const {
          data: { stables },
        } = await configAPI.getHorseTypes();
        this.stables = stables;
        await this.$store.dispatch('errorMessage/resetError');
      } catch (err) {
        await this.$store.dispatch('errorMessage/setErrorMessage', err.message);
      }
    },
  },
};
</script>
