<template>
  <v-card
    class="ma-5"
    outlined
  >
    <v-toolbar
      flat
      color="primary"
      dark
    >
      <v-col
        v-if="!kees.customer"
        cols="4"
      >
        <search-customer
          :outlined="false"
          :clearable="true"
          :customer="kees.customer"
          @emit-customer="addCustomer"
        />
      </v-col>
      <v-toolbar-title>
        {{ `${ownerName(kees.customer)} - ${horseName(kees.horse)}` }}
      </v-toolbar-title>
      <v-spacer />
      <v-progress-circular
        v-if="loading"
        indeterminate
        :width="2"
        color="white"
      />
      <v-spacer />
      <v-col
        v-if="
          kees.mainActivity &&
            (kees.mainActivity.toLowerCase() === caseActivities.EMBRYOTRANSFER.toLowerCase() ||
              kees.mainActivity.toLowerCase() === caseActivities.KUNSTMATIGE_INSEMINATIE.toLowerCase() ||
              kees.mainActivity.toLowerCase() === caseActivities.ICSI_TRANSFER.toLowerCase())
        "
        cols="3"
      >
        <search-horse
          :horse="kees.extra"
          horse-type="Hengst"
          :stud-only="true"
          :clearable="true"
          :solo-inverted="true"
          :dense="true"
          :flat="true"
          @emit-horse="selectStud"
        />
      </v-col>
      <v-col cols="3">
        <v-select
          v-model="kees.mainActivity"
          :menu-props="{ offsetY: true }"
          solo-inverted
          hide-details
          flat
          dense
          label="Hoofdactiviteit"
          :items="activities"
        />
      </v-col>
      <single-tag-menu
        :current="kees.status"
        :options="statuses"
        :outlined="true"
        :disabled="!!kees.invoice"
        :disable-child="{ child: 'Factureerbaar', check: !!checkForInvalidPension }"
        color="secondary"
        text-color="secondary"
        @select="selectStatus"
      />
    </v-toolbar>
    <v-tabs
      v-model="tab"
      show-arrows
      center-active
    >
      <!--   GENERAL   -->
      <v-tab
        key="general"
        href="#general"
        class="d-print-none"
      >
        <v-icon left>
          mdi-alpha-o-circle
        </v-icon>
        {{ kees.reference }}
      </v-tab>
      <v-tab-item
        value="general"
        class="my-5"
      >
        <case-overview
          :id="id"
          :kees="kees"
          :combined="combined"
          :initial-status="initialStatus"
          :success="success"
          @close-case="closeCase"
          @save-case="save"
          @post-invoice="postInvoice"
        />
      </v-tab-item>

      <!--   CERTIFICATES   -->
      <v-tab
        v-if="!!getProtocolsWithCert.length"
        key="certificates"
        href="#certificates"
        class="d-print-none"
      >
        <v-icon left>
          mdi-alpha-r-circle
        </v-icon>
      </v-tab>
      <v-tab-item
        value="certificates"
        class="ma-5"
      >
        <protocols-certificate-table
          title="Protocols met een certificaat"
          :protocols="getProtocolsWithCert"
          :case-id="id"
        />
      </v-tab-item>

      <!--   NEW   -->
      <v-tab
        v-if="kees.status !== 'Gesloten'"
        key="new"
        href="#new"
        class="d-print-none"
      >
        <v-icon>mdi-plus</v-icon>
      </v-tab>
      <v-tab-item
        value="new"
        class="ma-5"
      >
        <research-sheet-form
          :sheet="newSheet"
          :horse="kees.horse"
          :customer="kees.customer"
          :kees="kees"
          @update-sheet="updateSheet"
        />
      </v-tab-item>

      <!--   SHEETS   -->
      <v-tab
        v-for="sheet in kees.researchSheets"
        :key="sheet._id"
        :href="`#${sheet._id}`"
        class="d-print-none"
      >
        {{ formatDate(sheet.researchDate) }}
      </v-tab>
      <v-tab-item
        v-for="sheet in kees.researchSheets"
        :key="sheet._id"
        :value="sheet._id"
        class="ma-5 d-print-none"
      >
        <research-sheet-form
          :sheet="sheet"
          :horse="kees.horse"
          :customer="kees.customer"
          :kees="kees"
          :protocols="sheet.protocols"
          :labos="sheet.labos"
          @update-sheet="updateSheet"
        />
      </v-tab-item>
    </v-tabs>
    <snackbar
      :top="true"
      :snackbar="snackbar"
      :color="snackColor"
      :text="snackText"
      @timedout="snackbar = $event"
    />
  </v-card>
</template>

<script>
import CaseOverview from '@/components/case/CaseOverview';
import SingleTagMenu from '@/components/common/SingleTagMenu';
import Snackbar from '@/components/common/Snackbar';
import researchSheetForm from '@/components/researchSheet/Form';
import SearchHorse from '@/components/search/SearchHorse';
import { copyWithoutLayout, formatDate, horseName, ownerName } from '@/Helpers';
import { CaseAPI, configAPI, teamleaderAPI } from '@/services';
import SearchCustomer from '@/components/search/SearchCustomer';
import ProtocolsCertificateTable from '@/components/researchSheet/ProtocolsCertificateTable.vue';
import { caseActivities } from '@/consts/index.ts';

copyWithoutLayout();

export default {
  components: {
    SearchCustomer,
    CaseOverview,
    researchSheetForm,
    SingleTagMenu,
    Snackbar,
    SearchHorse,
    ProtocolsCertificateTable,
  },
  props: {
    id: { type: String, required: true },
  },
  data: () => ({
    kees: { researchSheets: [], pension: {} },
    statuses: [],
    activities: [],
    initialStatus: '',
    combined: { products: [], labos: [], protocols: [] },
    newSheet: {
      protocols: [],
      products: [],
      labos: [],
      billable: true,
      information: undefined,
      researchDate: new Date(),
    },
    loading: false,
    snackbar: false,
    snackText: '',
    snackColor: '',
    success: false,
    caseActivities,
  }),
  computed: {
    tab: {
      set(tab) {
        if (this.$route.query.tab !== tab) {
          this.$router.replace({ query: { ...this.$route.query, tab } });
        }
      },
      get() {
        return this.$route.query.tab;
      },
    },
    checkForInvalidPension() {
      if (this.kees.pension.length) {
        return this.kees.pension.some((p) => !p.from || !p.to || !p.type);
      }
      return false;
    },
    getProtocolsWithCert() {
      return this.combined.protocols.filter((p) => p.protocol.reportType.length > 0);
    },
  },
  watch: {
    '$route.params.id': function (newId) {
      this.id = newId;
    },
  },
  beforeMount() {
    if (this.id !== 'undefined') {
      this.getCase();
    }
  },
  mounted() {
    this.getCaseConfig();
  },
  methods: {
    ownerName,
    formatDate,
    horseName,
    selectStatus(selected) {
      this.kees.status = selected;
      if (selected === 'Factureerbaar') {
        this.postInvoice();
      }
    },
    addCustomer(customer) {
      this.kees.customer = customer;
    },
    selectStud(horse) {
      this.kees.extra = horse ? horse._id : null;
    },
    updateSheet(data) {
      if (data) {
        this.getCase();
        this.newSheet = {
          billable: true,
          protocols: [],
          products: [],
          labos: [],
          information: {
            ...data.information,
            rightOvary: {
              follicles: data.information.rightOvary.follicles.filter((follicle) => !!follicle.follicleTag),
            },
            leftOvary: {
              follicles: data.information.leftOvary.follicles.filter((follicle) => !!follicle.follicleTag),
            },
          },
          researchDate: new Date(),
        };
        this.showSnackbar('success', 'Onderzoeksfiche succesvol opgeslagen');
      }
    },
    showSnackbar(color, text) {
      this.snackColor = color;
      this.snackText = text;
      this.snackbar = true;
    },
    async getCaseConfig() {
      await this.$store.dispatch('errorMessage/resetError');
      try {
        const {
          data: { statuses, activities },
        } = await configAPI.getCaseStatus();
        this.statuses = statuses;
        this.activities = activities;
      } catch (err) {
        await this.$store.dispatch('errorMessage/setErrorMessage', err);
      }
    },
    async getCase() {
      this.loading = true;
      await this.$store.dispatch('errorMessage/resetError');
      try {
        const { data } = await CaseAPI.get(this.id);
        this.kees = data;
        this.initialStatus = this.kees.status;
        this.combined = this.kees.researchSheets.reduce(
          (acc, curr) => ({
            products: [...acc.products, ...curr.products.map((product) => ({ ...product, date: curr.researchDate }))],
            labos: [...acc.labos, ...curr.labos.map((lab) => ({ ...lab, date: curr.researchDate }))],
            protocols: [...acc.protocols, ...curr.protocols.map((protocol) => ({ ...protocol, date: curr.researchDate }))],
          }),
          { products: [], labos: [], protocols: [] },
        );
        this.tab = this.$route.query.tab;
        this.kees.pension = this.kees.pension.map((pension, idx) => ({
          ...pension,
          index: pension._id || idx,
        }));
      } catch (err) {
        await this.$store.dispatch('errorMessage/setErrorMessage', err);
      } finally {
        this.loading = false;
      }
    },
    async closeCase() {
      this.errMsg = '';
      try {
        this.kees.status = 'Gesloten';
        await this.save();
        await this.$router.push('/cases');
      } catch (err) {
        this.errMsg = err.message;
      }
    },
    async save(saveOnly) {
      await this.$store.dispatch('errorMessage/resetError');
      try {
        this.kees.pension = this.kees.pension.map((pen) => {
          if (typeof pen.to !== 'string') {
            return { ...pen, to: pen.to ? pen.to.setHours(12) : null };
          }

          return pen;
        });
        await CaseAPI.put({ ...this.kees, mainActivity: this.kees.mainActivity || '-' });
        if (saveOnly) {
          await this.getCase();
          this.showSnackbar('success', 'Opgeslagen!');
        }
      } catch (err) {
        await this.$store.dispatch('errorMessage/setErrorMessage', err);
      }
    },
    async postInvoice(selected) {
      await this.$store.dispatch('errorMessage/resetError');
      this.success = false;
      this.loading = true;
      try {
        await this.save();
        const { data } = await teamleaderAPI.postInvoice(this.kees, selected);
        this.kees.invoice = data.id;
        this.success = true;
        this.showSnackbar('success', 'Factuur opgemaakt!');
        await this.getCase();
      } catch (err) {
        await this.$store.dispatch('errorMessage/setErrorMessage', err);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
